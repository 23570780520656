/* Contact.css */
.contact-section {
    padding: 2rem 1rem; /* Reduced padding to make the content appear wider */
    background-color: #f5f5f5;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 70vh;
  }
  
  .contact-container {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    max-width: 1600px; /* Increased max-width for a wider content area */
    gap: 2rem;
  }
  
  .contact-info {
    flex: 1;
    padding: 1.5rem;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  }
  
  .contact-title {
    margin-bottom: 1rem;
    color: var(--blue-dark);
  }
  
  .contact-details {
    margin-bottom: 1.5rem;
    color: var(--blue-main);
  }
  
  .contact-map {
    flex: 1;
    min-height: 300px;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  }
  
  .contact-map iframe {
    border: 0;
    width: 100%;
    height: 100%;
    border-radius: 8px;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .contact-container {
      flex-direction: column;
    }
  
    .contact-map {
      min-height: 250px;
    }
  }
    