.title-section {
  position: relative;
  padding: 4rem;
  background-color: #7fa6b7ee;
  z-index: 10;
  overflow: hidden;
  display: flex;
  justify-content: flex-end;
}

.solutions-title {
  color: white;
  text-shadow: 0px 4px 8px rgba(117, 147, 160, 0.5);
  padding: 10px 40px;
  background-color: var(--blue-dark);
  border-radius: 8px;
  position: absolute;
  top: 20px;
  right: 20px;
  white-space: nowrap;
  opacity: 0;
  transform: translateX(100px);
  transition: opacity 0.6s ease-out, transform 2.5s ease-out;
  padding-left: 20%;
  padding-right: 10%;
}

.solutions-title.slide-in {
  opacity: 1;
  transform: translateX(0);
}

.services-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem 2rem;
  background-color: #7fa6b7ee;
  position: relative;
  z-index: 1;
}

.widget {
  position: relative;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 51, 102, 0.2);
  padding: 2rem;
  margin: 1rem 0;
  width: 100%;
  max-width: 1100px;
  overflow: hidden;
  text-align: center;
  display: flex;
  flex-direction: column;
  opacity: 0;
  transform: translateY(100px);
  transition: opacity 1s ease-out, transform 1s ease-out;
}

.widget.fade-in {
  opacity: 1;
  transform: translateY(0);
}

.efficiency-content {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  text-align: left;
}

.efficiency-img {
  width: 150px;
  height: auto;
  border-radius: 8px;
}

.more-info {
  flex: 1;
}

.marketing-widgets {
  display: flex;
  flex-direction: row;
  gap: 1.5rem;
  margin-top: 1.5rem;
  width: 100%;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
}

.marketing-widget {
  background-color: #f9f9f9b0;
  border-radius: 8px;
  padding: 1.5rem;
  text-align: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.marketing-img {
  width: 100%;
  height: 100px;
  object-fit: cover;
  border-radius: 8px;
  margin-bottom: 1rem;
}

.info-button {
  margin-top: 1rem;
  background-color: var(--blue-dark);;
  color: white;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 50%;
  align-self: center;
}

.info-button:hover {
  background-color: rgba(189, 183, 183, 0.847);
  color: var(--blue-dark);
}

.marketing-widget p {
  margin-top: 1rem;
  text-align: justify;
  color: var(--blue-dark);
  display: none;
}

.marketing-widget.show-info p {
  display: block;
}

.infrastructure-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2rem;
  text-align: left;
}

.infrastructure-img {
  width: 150px;
  height: auto;
  border-radius: 8px;
}

.more-info {
  flex: 1;
}
