html {
  scroll-behavior: smooth;
}

:root {
  --blue-light: #cadbe4;
  --blue-main: #0288d1;
  --blue-dark: #0d4664;
  --blue-darker: #013d70;

  --font-primary: "Quicksand", sans-serif;
}

body {
  font-family: var(--font-primary);
  margin: 0;
  padding: 0;
  color: var(--blue-dark);
}

.App-header {
  position: fixed;
  top: 0;
  width: 100%;
  background-color: var(--blue-dark);
  color: white;
  padding: 1rem 1.5rem;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: space-around;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
}

.logo-container {
  display: flex;
}

.logo {
  height: auto;
  width: 50%;
}

.nav-links {
  display: flex;
  gap: 1.5rem;
}

.nav-links a {
  color: white;
  text-decoration: none;
  padding-bottom: 3px;
  font-size: 1rem;
}

.nav-links a.active {
  border-bottom: 2px solid var(--blue-main);
}



p {
  text-align: justify;
}

#services {
  background-color: #7fa6b7ee;
}

section {
  align-items: center;
  justify-content: center;
  height: auto;
}

section:nth-of-type(2n) {
  background-color: var(--blue-main);
  color: white;
}

p {
  font-size: 1rem;
}

h1 {
  font-size: 1.3rem;
}
h2 {
  font-size: 1.2rem;
}
h3 {
  font-size: 1.2rem;
}
