.about-section {
  background-color: #041320;
  padding: 4rem;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.about-title {
  color: white;
  text-shadow: 0px 4px 8px rgba(117, 147, 160, 0.5);
  padding: 10px 40px;
  background-color: rgba(50, 173, 207, 0.973);
  border-radius: 8px;
  position: absolute;
  top: 20px;
  left: -10px;
  white-space: nowrap;
  opacity: 0;
  transform: translateY(30px);
  transition: opacity 0.6s ease-out, transform 2.5s ease-out;
  padding-left: 10%;
  padding-right: 20%;
  margin-bottom: 2rem;
}

.about-title.slide-in {
  opacity: 1;
  transform: translateY(0);
}

.about-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
  width: 100%;
  max-width: 1400px;
  margin-top: 5rem;
}

.about-widget {
  background-color: white;
  border: 2px solid var(--blue-dark);
  padding: 1.5rem;
  text-align: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
}

.widget-content {
  display: flex;
  align-items: center;
  gap: 1.5rem;
  flex-direction: row;
}

.widget-img img {
  width: 120px;
  height: 120px;
  object-fit: cover;
  border-radius: 50%;
  box-shadow: 0 4px 10px rgba(0, 51, 102, 0.2);
}

.widget-info {
  text-align: left;
  flex: 1;
}

.team-member-name {
  color: var(--blue-dark);
  margin-bottom: 0.5rem;
}

.team-member-position {
  color: var(--blue-main);
  margin-bottom: 0.5rem;
}

.team-member-description {
  color: var(--blue-dark);
}

.centered-widget {
  grid-column: span 2;
  justify-content: center;
}
