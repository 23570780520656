.partner-section {
  padding: 50px 0;
  background-color: white;
  text-align: center;
}

.partner-carousel {
  width: 100%;
  overflow: hidden;
  position: relative;
}

.carousel-track {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  animation: scroll 30s linear infinite;
}

.partner-logo {
  width: 150px;
  height: auto;
  margin: 0 80px;
  object-fit: contain;
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}
