@keyframes popUp {
  0% {
    opacity: 0;
    transform: translateY(30px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.home-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70vh;
  padding: 2rem;
  padding-top: calc(2rem + 100px);
  background: white;
}

.text-box {
  background-color: rgba(232, 236, 237, 0.979);
  color: #020101;
  padding: 2rem;
  padding-top: 3rem;
  max-width: 900px;
  box-shadow: 0 20px 30px rgba(0, 51, 102, 0.2);
  line-height: 1.6;
  border-radius: 10px;
  position: relative;
  text-align: justify;
  opacity: 0;
  animation: popUp 4s ease-out forwards;
}

.text-box p {
  margin-bottom: 1rem;
}

